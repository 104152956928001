<template>
  <div
    class="container"
    :style="{ backgroundImage: 'url(' + live.phoneBackUrl + ')' }"
  >
    <Chat />
    <SvgPlayer />
    <Sign />
    <GiftPop />
    <EnterLive />
    <WinNotice/>
    <WinRate/>
<!--    <WebS/>-->
  </div>
</template>
<script>
import Chat from "@views/home/model/Chat";
import SvgPlayer from "@views/home/model/SvgPlayer";
import Sign from "@views/home/model/Sign";
import GiftPop from "@views/home/model/GiftPop";
import EnterLive from "@views/home/model/EnterLive";
import WinNotice from "@views/home/model/WinNotice"
import WinRate from "@views/home/model/WinRate";
import WebS from "@views/home/model/WebS"
import { mapGetters } from "vuex";
export default {
  name: "Index",
  components: {
    GiftPop,
    Sign,
    SvgPlayer,
    Chat,
    EnterLive,
    WinNotice,
    WinRate,
    WebS
  },
  computed: mapGetters(["live"])
}
</script>
<style>
body {
  padding-right: 0px !important;
  overflow: hidden;
}
.container {
  width: 100vw;
  height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-position: center;
}
</style>
