import ReconnectingWebSocket from 'reconnecting-websocket';
import $store from "../store";

let rws = null;
let globalCallback = null;

//初始化websocket
const initWebsocket = () => {
  const token = $store.state.app.token;
  const url = process.env.VUE_APP_WS_URL + "/chatImServer?client=h5";
  // 建立websocket 连接
  rws = new ReconnectingWebSocket(url,token);
  rws.addEventListener("open", () => {
    if (!rws) return;
    if (rws.readyState === 1) {
      webSocketOpen();
    }
  });

  //收信
  rws.addEventListener("message", (e) => {
    webSocketOnMessage(e.data);
  });

  // 错误时进行的处理
  rws.addEventListener("error", (e) => {
    console.error(e.message);
  });

  // 关闭时进行的操作
  rws.addEventListener("close", () => {
    webSocketClose();
  });
}

//心跳设置
const heartCheck = {
  timeout: 13000, //每段时间发送一次心跳包 这里设置为13s
  timeoutObj: null, //延时发送消息对象（启动心跳新建这个对象，收到消息后重置对象）
  start: function () {
    this.timeoutObj = setInterval(function () {
      // debugger
      const token = $store.state.app.token;
      let checkMsg = JSON.stringify({
        // 与后端约定，传点消息，保持链接
        msgCode: "heart",
        liveId: $store.state.live.id,
        token: token,
      });
      sendSock(checkMsg);
    }, this.timeout);
  },

  reset: function () {
    clearInterval(this.timeoutObj);
    this.start();
  },
};
//连接socket建立时触发
const webSocketOpen = () => {
  console.log("建立成功，发送消息");
  heartCheck.start();
  // const token = $store.state.app.token;
  // const liveId = $store.state.live.id;
  // if(liveId){
  //   const checkMsg = JSON.stringify({
  //     // 与后端约定，传点消息，保持链接
  //     msgCode: "heart",
  //     liveId: $store.state.live.id,
  //     token: token,
  //   });
  //   sendSock(checkMsg, () => { });
  // }
}

//客户端接收服务端数据时触发,e为接受的数据对象
const webSocketOnMessage = (data) => {
  console.log(data);
  let O_o = JSON.parse(data);
  // globalCallback(O_o);//JSON.parse(decrypt(data))
  if (!O_o) {
    heartCheck.reset();
  } else {
    globalCallback(O_o);
  }
}

//发送数据
const webSocketSend = (data) => {
  rws.send(data);
}

//关闭socket
const webSocketClose = () => {
  rws.close();
  console.log("对话连接已关闭");
}

// 设置接收消息的回调函数
const setMessageCallback = (callback) => {
  globalCallback = callback;
}

// 实际调用的方法
const sendSock = (agentData) => {
  // console.log("重新连接")
  // globalCallback = callback;
  // console.log("rws.readyState", rws);
  switch (rws.readyState) {
    //OPEN：值为1，表示连接成功，可以通信了。
    case rws.OPEN:
      // console.log("OPEN")
      webSocketSend(agentData);
      break;
    // CONNECTING：值为0，表示正在连接。
    case rws.CONNECTING:
      console.log("CONNECTING", globalCallback)
      setTimeout(function () {
        //@ts-ignore
        webSocketSend(agentData, globalCallback);
      }, 1000);
      break;
    // CLOSING：值为2，表示连接正在关闭。
    case rws.CLOSING:
      console.log("CLOSING")
      setTimeout(() => {
        //@ts-ignore
        webSocketSend(agentData, globalCallback);
      }, 1000);
      break;
    // CLOSED：值为3，表示连接已经关闭，或者打开连接失败。
    case rws.CLOSED:
      console.log("CLOSED")
      // do something
      break;
    default:
      console.log("default")
      break;
  }
}

export { initWebsocket, webSocketClose, sendSock,setMessageCallback  };